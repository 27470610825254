/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Box,
  CircularProgress,
  DataTable,
  Flex,
  Icon,
  IconButton,
  ModalDialog,
  renderDateTime,
} from 'everchain-uilibrary'
import { ActivityUnmaskDataFileResponse } from 'src/data/features/post/portfolio/types'
import { getNDADocumentUriByFile } from 'src/data/features/get/portfolio/portfolio'

interface ApproveNDADraftModalProps {
  workflowId?: number
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  activityUnmaskDataFiles?: ActivityUnmaskDataFileResponse[]
}

const notifyError = notistackOptions('error')

const UnmaskedDataNDAFilesModal: React.FC<ApproveNDADraftModalProps> = ({
  workflowId,
  isOpen,
  setIsOpen,
  activityUnmaskDataFiles,
}: ApproveNDADraftModalProps) => {
  const [downloadFileId, setDownloadFileId] = useState<number>()

  const { enqueueSnackbar } = useSnackbar()

  const { isFetching: loadingUriFile } = useCustomQuery<any>(
    ['getNDADocumentUriByFile', downloadFileId],
    async () => {
      if (downloadFileId && workflowId) {
        await getNDADocumentUriByFile(workflowId, downloadFileId)
          .then((data: string) => {
            if (data) {
              window.location.href = data
            }
            return data
          })
          .catch(() => {
            enqueueSnackbar('Download file failed', notifyError)
          })
          .finally(() => {
            setDownloadFileId(undefined)
          })
      }
    },
    { enabled: !!downloadFileId }
  )

  const gridColumns: any[] = [
    {
      title: 'File Type',
      field: 'fileType',
      show: true,
    },
    {
      title: 'User Type',
      field: 'userType',
      show: true,
    },
    {
      title: 'Uploaded Date',
      field: 'uploadedUtc',
      show: true,
      render: (props: any) => renderDateTime(props),
    },
    {
      title: 'Download NDA',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            <Flex justifyContent="center">
              {downloadFileId === props.dataItem.id && loadingUriFile ? (
                <Box pt="2px" pb={2} pr={1}>
                  <CircularProgress width="20px" />
                </Box>
              ) : (
                <IconButton
                  onClick={() => setDownloadFileId(Number(props.dataItem.id))}
                >
                  <Icon name="Download" />
                </IconButton>
              )}
            </Flex>
          </td>
        )
      },
    },
  ]

  return (
    <ModalDialog
      header="NDA Versions"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      hideOkButton
      maxwidth="60%"
    >
      <DataTable
        maxHeight="54vh"
        data={activityUnmaskDataFiles}
        gridColumns={gridColumns}
      />
    </ModalDialog>
  )
}

export default UnmaskedDataNDAFilesModal
